<template>
  <div
    class="box-shadow text-base relative flex flex-col overflow-hidden rounded-lg border border-grid p-1 font-normal text-textColor"
    style="background: rgba(82, 104, 184, 0.04)"
  >
    <div class="text-xs p-2">
      <div class="h-full">
        <div class="flex items-center space-x-2 py-1">
          <img
            v-if="data.data?.icon || data.data?.image || data.data?.game_api"
            :src="
              data.data?.icon ||
              data.data?.image ||
              getGameIcon(convertUnderScoreToDash(data.data?.game_api))
            "
            class="h-10 rounded-full"
          />
          <div
            v-else
            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-400 text-white"
          ></div>
          <Tooltip
            :text="data?.data?.L1_title || data?.mapping_id"
            v-if="(data?.data?.L1_title || data?.mapping_id).length > 12"
          >
            <div class="text-base font-semibold text-white">
              {{
                capitalizeWords(
                  data?.data?.L1_title || data?.mapping_id,
                  '_',
                ).slice(0, 12) +
                (capitalizeWords(data?.data?.L1_title || data?.mapping_id, '_')
                  .length > 12
                  ? '...'
                  : '')
              }}
            </div>
          </Tooltip>
          <div v-else class="text-base font-semibold text-white">
            {{
              capitalizeWords(
                data?.data?.L1_title || data?.mapping_id,
                '_',
              ).slice(0, 12) +
              (capitalizeWords(data?.data?.L1_title || data?.mapping_id, '_')
                .length > 12
                ? '...'
                : '')
            }}
          </div>
        </div>
        <div class="py-1 font-sourceCodePro font-sourceSans text-xxs">
          <div
            v-for="metric in data?.data?.metrics || metrics"
            :key="Object.keys(metric)[0]"
            class="flex justify-between border-b border-gray-700 border-opacity-60 py-2"
          >
            <span>{{ Object.keys(metric)[0] }}</span>
            <span>{{
              data && data.data ? formatEntity(metric, data) : '-'
            }}</span>
          </div>
          <div class="h-full pt-2">
            <div class="font-medium text-white">Description:</div>
            <div
              class="w-full overflow-hidden text-ellipsis whitespace-normal text-xxs"
            >
              <Tooltip
                :text="data?.description?.replace(/_/g, ' ')"
                v-if="data?.description && data?.description.length > 40"
              >
                <p
                  class="flex items-start overflow-hidden text-left"
                  :class="component && !data?.data?.metrics ? 'h-[4.5rem]' : 'h-[2.5rem]'"
                >
                  {{
                    data?.description?.replace(/_/g, ' ').slice(0, 60) + '...'
                  }}
                </p>
              </Tooltip>
              <div v-else>
                <p
                  class="flex items-start overflow-hidden text-left"
                  :class="component && !data?.data?.metrics ? 'h-[4.5rem]' : 'h-[2.5rem]'"
                >
                  {{ data?.description?.replace(/_/g, ' ') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-xs pt-1">
          <a
            :href="data?.link || data?.data?.url"
            class="flex cursor-pointer items-center text-blue-500"
            target="_blank"
            >Go To Page
            <ArrowUpRightIcon class="ml-2 h-4 w-4 font-semibold" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowUpRightIcon } from '@heroicons/vue/24/outline';
import {
  titleize,
  formatBigPricing,
  capitalizeWords,
  convertUnderScoreToDash,
} from '../composeables/filters.js';
import Tooltip from '../Shared/Tooltip.vue';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps({
  classes: { type: String, default: '' },
  data: { type: Object, default: {} },
  metrics: { type: Array, default: ['Name', 'Price', 'Stats', 'Description'] },
  component: { type: String, default: null },
});

const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
function formatEntity(metric, data) {
  const value = data?.data[Object.values(metric)[0]];
  if (value === undefined) return '-';

  const formattedValue =
    metric.decimals !== undefined
      ? parseFloat(value).toFixed(metric.decimals)
      : formatBigPricing(value);

  return metric.prefix ? `${metric.prefix}${formattedValue}` : formattedValue;
}
function getGameIcon(name) {
  name = name.replace(/\s/g, '');
  name = name == 'dragoncrypto' ? 'dragoncryptogame' : name;
  let extension = [
    'castlecrush',
    'dragoncryptogame',
    'paradisetycoon',
  ].includes(name)
    ? '.png'
    : '.svg';
  return store.getters.projectImagesBaseLink + name + extension;
}
</script>
