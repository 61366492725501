<template>
  <div
    class="modal box-shadow relative flex !w-full flex-col items-center font-normal text-xs"
    id="privacyPolicyModal"
  >
    <div class="prose prose-sm max-w-full text-whiteSmokeAlpha-56">
      <div class="flex w-full justify-end">
        <XMarkIcon
          class="h-5 w-5 cursor-pointer hover:scale-125"
          @click="closeModal"
        />
      </div>
      <h2 class="!mt-0 flex justify-center text-center text-gray-300">
        API Terms of Use – FROSTY METRICS
      </h2>
      <div class="h-96 w-full overflow-auto md:h-80">
        <h4 class="text-gray-300">Introduction</h4>
        <p>
          This API Terms of Use Agreement together with the Terms of Use and
          Privacy Policy contained on Our Frosty Metrics Website
          https://frostymetrics.com/ (“Our Website”) and any documents that this
          API Terms of Use Agreement incorporates by reference (collectively
          “The Agreement ”) governs your access to and use of the Frosty Metrics
          application programming interface ("API") provided by The Tie Inc.
          (“The Tie,” “Company,” “We,” “Our,” or “Us”). By accessing or using
          the API, you, the authorized user ("User" or “You”) is agreeing to be
          bound by the terms and conditions set forth in this Agreement, and
          this Agreement is a legal contract between You and The Tie and applies
          to all Users of the API. If there is a conflict between the general
          Terms of Use and Privacy Policy contained on Our Website and these
          specific API Terms of Use the latter will control.
        </p>
        <p>
          By using the API, you acknowledge that you have read, understand, and
          agree to be bound by this Agreement. If you do not agree to this
          Agreement, you must not access or use the API. The Tie reserves the
          right to update or modify this Agreement at any time without prior
          notice. Your continued use of the API after any such changes
          constitutes your acceptance of the new terms and conditions
        </p>
        <h4 class="text-gray-300">Grant of License</h4>
        <p>
          Pursuant to the terms and conditions of this Agreement, Provider
          hereby grants to User a non-exclusive, non-transferable, revocable
          license to use the API for User's personal use or internal or external
          business operations whether for commercial or non-commercial purposes.
          This license does not include the right to sublicense or sell the API.
          However, the User may share data obtained through the API with third
          parties for business purposes, provided that the third parties agree
          to comply with the terms of this Agreement. User agrees not to modify,
          reverse engineer, decompile, disassemble, or otherwise attempt to
          derive the source code of the API and not to directly or indirectly
          manipulate or to attempt to manipulate Frosty Metrics “Chill Factor”
          or scores.
        </p>
        <p>
          User shall comply with all applicable laws, regulations, and
          guidelines in using the API and will not use the API in any manner
          that infringes or violates the intellectual property rights or
          proprietary rights of The Tie or any third party. Furthermore, User
          acknowledges that the API is subject to change at The Tie's
          discretion, and The Tie reserves the right to modify or discontinue
          the API at any time without notice to User.
        </p>
        <h4 class="text-gray-300">API Key Requirement</h4>
        <p>
          To access and use the APIs, the User must obtain an API key. The API
          key serves as a unique identifier for the User and must be included in
          all API requests. An API key can be obtained by completing an API key
          request form at https://frostymetrics.com/api. The provision and use
          of the API key is expressly subject to the terms and conditions of
          this Agreement.
        </p>
        <p>
          The API key is provided on a non-exclusive, non-transferable, and
          revocable basis. It is intended solely for the User and may not be
          shared with, or used by, any third party without the express written
          consent of The Tie. The Tie reserves the right to revoke the API key
          at any time on a discretionary or automated schedule, at its option,
          particularly in cases of misuse or breach of this Agreement or for
          inactivity.
        </p>
        <h4 class="text-gray-300">Rate Limits</h4>
        <p>
          To ensure fair use and optimal performance, the User shall adhere to
          the rate limits as published by The Tie in the API documentation which
          can be found on Our Frosty Metric’s website. These rate limits are
          subject to change at The Tie’s discretion, and the User will be
          informed of any such changes through updates to the API documentation
          which can be found at https://docs.frostymetrics.com. It is the User’s
          responsibility to check for any updates and failure to comply with
          these rate limits may result in temporary or permanent suspension of
          the User's access to the APIs.
        </p>
        <h4 class="text-gray-300">Attribution Requirement</h4>
        <p>
          The User is granted a non-exclusive, non-transferable, and revocable
          license to use the APIs for personal, internal or external business
          operations in accordance with the terms of this Agreement. As a
          condition of this license, the User is required to provide attribution
          to The Tie in any product, service, or application that utilizes the
          APIs. This attribution must include the phrase "Powered by Frosty
          Metrics" prominently displayed within the product, service, or
          application, unless another form of attribution is agreed to in
          writing by The Tie. Failure to comply with this attribution
          requirement will result in the revocation of the User's license to use
          the API.
        </p>
        <h4 class="text-gray-300">
          Compliance with Privacy Policy and Terms of Use
        </h4>

        <p>
          User expressly acknowledges and agrees that Company may log API
          queries for any legitimate business purpose including but not limited
          to use for monitoring and improving its services.
        </p>
        <p>
          The User acknowledges and agrees to comply with the Privacy Policy and
          Terms of Use of The Tie on Our Frosty Metrics Website
          https://frostymetrics.com/, as they may be amended from time to time
          as well as with any restrictions set forth the API documentation or
          otherwise. These documents govern the use of the API and are available
          on The Tie's Frosty Metrics website. It is the User's responsibility
          to review these documents periodically to ensure continued compliance.
        </p>
        <p>
          By accessing or using the APIs, the User signifies their acceptance of
          the Privacy Policy and Terms of Use. Failure to adhere to these
          guidelines may result in termination of the User's access to the APIs,
          at the discretion of The Tie. Furthermore, the User agrees to ensure
          that any Product, Service, or Application developed using the API will
          also comply with these policies.
        </p>
        <p>
          Any use of the API that violates the Privacy Policy or Terms of Use
          may lead to immediate revocation of the User's API key and access
          rights, without prior notice. The Tie reserves the right to update or
          modify these policies at any time, and continued use of the APIs after
          such modifications will constitute acceptance of the changes.
        </p>
        <h4 class="text-gray-300">API Key Suspension or Termination</h4>
        <p>
          The Tie reserves the right, in its sole discretion, to suspend or
          terminate the API key assigned to the User at any time, for any reason
          or no reason, including but not limited to breaches of this Agreement,
          the unauthorized use of the APIs, or actions that may harm The Tie or
          its services. Upon suspension or termination, the User must
          immediately cease all use of the APIs and delete any stored data
          obtained using the API, unless otherwise directed by The Tie.
        </p>
        <p>
          Provider will make reasonable efforts to notify the User of any
          suspension or termination of the API key, including the reason for
          such action, if applicable. The User acknowledges that the Provider is
          not liable for any direct or indirect damages or losses that may
          result from the suspension or termination of the API key.
        </p>
        <h4 class="text-gray-300">INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          You acknowledge that the API, including all copyright, trademarks,
          service marks, logos, documents, , content, and/or other materials
          viewed or obtained from or through the API (collectively “Materials”),
          are owned and/or licensed by the The Tie or Our subsidiaries and
          affiliates, and are protected by trademark, copyright, and other
          intellectual property rights. These Terms of Use do not convey any
          rights of ownership or any licenses, explicit or implied, in or
          related to the API , Materials, or other intellectual property owned
          by the Company, beyond what is specifically granted in this Agreement.
        </p>
        <p>
          The Company name, the term “Frosty Metrics™,” the Company logo, and
          all related names, logos, product and service names, designs, and
          slogans are copyrights and/or trademarks of the Company or its
          affiliates or licensors. You must not use such marks without the prior
          written permission of the Company.
        </p>
        <p>
          The API and API contents, features, and functionality, are owned by
          the Company, its licensors, or other providers of such Materials and
          are protected by United States and international copyright, trademark,
          patent, trade secret, and other intellectual property or proprietary
          rights laws.
        </p>
        <h4 class="text-gray-300">DISCLAIMER OF LIABILTIY</h4>
        <p>
          THE TIE INC. SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITIES, LOSSES,
          OR ANY OTHER CONSEQUENCES THAT THE USER OR ANY THIRD-PARTY MAY INCUR
          AS A RESULT OF USING THE FROSTY METRICS API INCLUDING BUT NOT LIMITED
          TO DAMAGES ARISING FROM THE USER’S RELIANCE ON THE “CHILL FACTOR” OR
          OTHER SCORES PROVIDED BY THE API OR THE ACCURACY, RELIABILITY, OR
          COMPLETENESS OF THE DATA PROVIDED THROUGH THE API. OR FOR ANY AIRDROP
          USING SUCH DATA. THE USER ACKNOWLEDGES THAT THE TIE IS PROVIDING THE
          API TO THE USER AT NO CHARGE AND THAT THE TIE WOULD NOT PROVIDE THE
          API IN THE ABSENCE OF THIS FULL DISCLAIMER AND THE USER AGREES THAT IT
          ASSUMES FULL RESPONSIBILITY FOR THE USE OF THE API AND THE DATA
          OBTAINED THROUGH ITS USE. THE USER SHALL INDEMNIFY AND HOLD HARMLESS
          THE TIE INC. FROM ANY CLAIMS, DAMAGES, OR LIABILITIES ARISING FROM
          SUCH USE, INCLUDING BUT NOT LIMITED TO THOSE RELATED TO ANY AIRDROP
          ACTIVITIES.
        </p>

        <p>
          FURTHER, THE TIE INC. SHALL NOT BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING
          BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA,
          OR OTHER INTANGIBLE LOSSES (EVEN IF THE TIE INC. HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE USER'S ACCESS TO
          OR USE OF, OR INABILITY TO ACCESS OR USE, THE API.
        </p>

        <p>
          THE FOREGOING LIMITATIONS ON LIABILITY SHALL APPLY TO THE FULLEST
          EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
        </p>
        <h4 class="text-gray-300">Amendments</h4>
        <p>
          The Tie may amend this Agreement at any time by providing notice to
          the User. Such notice may be provided in any of the following ways:
        </p>
        <p>
          By posting the revised Agreement on The Tie’s Frosty Metric’s website.
        </p>
        <p>
          Sending an email to the email address associated with the User's
          account.
        </p>
        <p>Any other method that ensures the User receives the notice.</p>
        <p>
          Any amendments will become effective immediately upon the posting of
          the revised Agreement on the Frosty Metric's website or as otherwise
          indicated in the notice. It is the User's responsibility to review the
          Agreement periodically for changes. Continued use of the APIs after
          any such changes shall constitute the User's consent to such changes.
        </p>
        <h4 class="text-gray-300">Governing Law</h4>
        <p>
          This Agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the laws of the State of Delaware, without giving
          effect to any choice or conflict of law provision or rule.
        </p>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          this Agreement or the APIs shall be instituted exclusively in the
          federal courts of the United States or the courts of the State in
          which the Provider's principal place of business is located. Each
          party irrevocably submits to the exclusive jurisdiction of such courts
          in any such suit, action, or proceeding. In the event that The Tie
          employs attorneys or otherwise incurs costs related to enforcing its
          rights under this Agreement, You shall reimburse The Tie for any
          reasonable attorneys’ fees and costs.
        </p>

        <p class="pt-2">(updated December 4, 2024)</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
const props = defineProps({
  showModal: Boolean,
});
const emit = defineEmits(['close-modal']);

function closeModal() {
  console.log('closeModal');
  emit('close-modal', { showModal: false });
}
</script>
